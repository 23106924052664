/*
*******************************************
@File: Banner Styles
*******************************************
*/
.banner {
    position: relative;
    height  : var(--banner-height) !important;
    width   : 100% !important;
}

.banner .banner-bg {
    position           : absolute;
    height             : var(--banner-height) !important;
    width              : 100% !important;
    background-color   : transparent;
    background-position: center center;
    background-size    : cover;
    background-repeat  : no-repeat;
    background-image   : url(../images/bg-cta.jpg);
}

.banner .banner-bg::before {
    content   : '';
    position  : absolute;
    width     : 100% !important;
    height    : var(--banner-height) !important;
    left      : 0;
    right     : 0;
    top       : 0;
    bottom    : 0;
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity   : 0.8;
}

.bannerDashboard {
    position: relative;
    height  : 243px !important;
    width   : 100% !important;
}

.bannerDashboard .banner-bg {
    position           : absolute;
    height             : 243px !important;
    width              : 100% !important;
    background-color   : transparent;
    background-position: center left;
    background-size    : 50%;
    background-repeat  : no-repeat;
    overflow           : hidden;
    border-radius      : 6px;
    background-image   : url(../images/placeholders/banner.jpg);
}

.bannerDashboard .banner-bg::before {
    content   : '';
    position  : absolute;
    width     : 100% !important;
    height    : 240px !important;
    left      : 0;
    right     : 0;
    top       : 0;
    bottom    : 0;
    background: linear-gradient(270deg, #005073 0%, #A1D11C 50%, rgba(185, 235, 49, 0.00) 80%);
    /* opacity   : 0.8; */
}


/**
  * Carousel 
  */
.banner-carousel.carousels {
    height    : var(--banner-height);
    background: transparent;
}

.banner-carousel.carousels .carousel.carousel-slider {
    height    : var(--banner-height);
    background: transparent;
}

.banner-carousel.carousels .carousel .slide {
    height: var(--banner-height);
}

.banner-carousel.carousels .carousels-item {
    height: var(--banner-height);
}

.banner-carousel.carousels .carousels-item::after {
    height: var(--banner-height);
    opacity: 0.2;
}

.banner-carousel.carousels .carousel .control-arrows {
    bottom    : 1rem;
    color     : #fff;
    background: rgba(0, 0, 0, 0.35);
}

.banner-carousel.carousels .carousel .control-arrows:hover {
    background: rgba(0, 0, 0, 0.95);
}

.banner-carousel.carousels .carousel .control-arrows.control-next {
    right: 1rem !important;
}

.banner-carousel.carousels .carousel .control-arrows.control-prev {
    left: 1rem !important;
}


/**
  * Leading 
  */
.banner .leading {
    z-index: unset !important;
}