/*
*******************************************
@File: Header Styles
*******************************************
*/
#header {
    transition: all 0.5s;
    z-index   : 1100;
    background: rgba(0, 0, 0, 0.9);
    height    : 70px;
}

#header.header-transparent {
    background: transparent;
}

#header.header-white {
    position  : relative;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
}

#header.header-scrolled {
    background: rgba(255, 255, 255, 0.99);
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
}

#header.header-white.header-scrolled {
    position: fixed;
}

#header .logo {
    font-size     : 34px;
    margin        : 0;
    padding       : 0;
    font-family   : "Montserrat", sans-serif;
    font-weight   : 700;
    letter-spacing: 3px;
    padding-left  : 10px;
    border-left   : 4px solid var(--tertiary-green);
}

#header .logo a {
    color: #fff;
}

#header .logo img {
    max-height: 50px;
}

@media (max-width: 992px) {
    #header .logo img {
        max-height: 30px;
    }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
    padding: 0;
}

.navbar-brand {
    margin-left: 1rem;
    color      : #fff;
}

.navbar-brand img {
    height: 45px;
    width : 45px;
}

@media (max-width: 992px) {
    .navbar-brand img {
        height: 40px;
        width : 40px;
    }
}

.navbar-brand #logo-simple {
    display: block;
}

.navbar-brand:hover #logo-simple {
    display: none;
}

.navbar-brand #logo-animated {
    display: none;
}

.navbar-brand:hover #logo-animated {
    display: block;
}

.navbar ul {
    margin     : 0;
    padding    : 0;
    display    : flex;
    list-style : none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar a,
.navbar a:focus,
#header.header-white .navbar a,
#header.header-white .navbar a:focus {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    padding        : 10px 0 10px 30px;
    font-family    : "Montserrat", sans-serif;
    font-size      : 13px;
    font-weight    : 600;
    color          : whitesmoke;
    white-space    : nowrap;
    text-transform : uppercase;
    transition     : 0.3s;
}

#header.header-scrolled .navbar a,
#header.header-scrolled .navbar a:focus,
#header.header-white .navbar a,
#header.header-white .navbar a:focus {
    color: #212121;
}

.navbar a i,
.navbar a:focus i,
#header.header-white .navbar a i,
#header.header-white .navbar a:focus i {
    font-size  : 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a,
#header.header-scrolled .navbar a:hover,
#header.header-scrolled .navbar .active,
#header.header-scrolled .navbar .active:focus,
#header.header-scrolled .navbar li:hover>a,
#header.header-white .navbar a:hover,
#header.header-white .navbar .active,
#header.header-white .navbar .active:focus,
#header.header-white .navbar li:hover>a {
    color: var(--tertiary-green);
}

.navbar .dropdown ul,
#header.header-white .navbar .dropdown ul {
    display   : block;
    position  : absolute;
    left      : 14px;
    top       : calc(100% + 30px);
    margin    : 0;
    padding   : 10px 0;
    z-index   : 99;
    opacity   : 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}

.navbar .dropdown ul li,
#header.header-white .navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a,
#header.header-white .navbar .dropdown ul a {
    padding       : 10px 20px;
    text-transform: none;
    color         : #666666;
}

.navbar .dropdown ul a i,
#header.header-white .navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a,
#header.header-white .navbar .dropdown ul a:hover,
#header.header-white .navbar .dropdown ul .active:hover,
#header.header-white .navbar .dropdown ul li:hover>a {
    color: var(--tertiary-green);
}


.navbar .dropdown:hover>ul,
#header.header-white .navbar .dropdown:hover>ul {
    opacity   : 1;
    top       : 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul,
#header.header-white .navbar .dropdown .dropdown ul {
    top       : 0;
    left      : calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul,
#header.header-white .navbar .dropdown .dropdown:hover>ul {
    opacity   : 1;
    top       : 0;
    left      : 100%;
    visibility: visible;
}

@media (max-width: 1366px) {

    .navbar .dropdown .dropdown ul,
    #header.header-white .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul,
    #header.header-white .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle.bx,
#header.header-white .mobile-nav-toggle.bx {
    color       : #fff;
    font-size   : 28px;
    cursor      : pointer;
    display     : none;
    line-height : 0;
    transition  : 0.5s;
    margin-right: 1rem;
}

#header.header-scrolled .mobile-nav-toggle.bx,
#header.header-white .mobile-nav-toggle.bx {
    color: #666666;
}

@media (max-width: 991px) {

    .mobile-nav-toggle.bx,
    #header.header-white .mobile-nav-toggle.bx {
        display: inline-block;
    }

    .navbar ul,
    #header.header-white .navbar ul {
        display: none;
    }
}

.navbar-mobile,
#header.header-white .navbar-mobile {
    position  : fixed;
    overflow  : hidden;
    top       : 0;
    right     : 0;
    left      : 0;
    bottom    : 0;
    background: rgba(26, 26, 26, 0.9);
    transition: 0.3s;
    z-index   : 999;
}

.navbar-mobile .mobile-nav-toggle,
#header.header-white .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top     : 30px;
    right   : 15px;
}

.navbar-mobile ul,
#header.header-white .navbar-mobile ul {
    display         : block;
    position        : absolute;
    top             : 55px;
    right           : 15px;
    bottom          : 15px;
    left            : 15px;
    padding         : 10px 0;
    background-color: #fff;
    overflow-y      : auto;
    transition      : 0.3s;
}

.navbar-mobile a,
#header.header-white .navbar-mobile a {
    padding  : 10px 20px;
    font-size: 15px;
    color    : #333333;
}

.navbar-mobile a:focus,
#header.header-white .navbar-mobile a:focus {
    color: var(--tertiary-green);
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a,
#header.header-white .navbar-mobile a:hover,
#header.header-white .navbar-mobile .active,
#header.header-white .navbar-mobile li:hover>a {
    color: var(--tertiary-green);
}

.navbar-mobile .dropdown ul,
#header.header-white .navbar-mobile .dropdown ul {
    position  : static;
    display   : none;
    margin    : 10px 20px;
    padding   : 10px 0;
    z-index   : 99;
    opacity   : 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li,
#header.header-white .navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a,
#header.header-white .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i,
#header.header-white .navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a,
#header.header-white .navbar-mobile .dropdown ul a:hover,
#header.header-white .navbar-mobile .dropdown ul .active:hover,
#header.header-white .navbar-mobile .dropdown ul li:hover>a {
    color: var(--tertiary-green);
}

.navbar-mobile .dropdown>.dropdown-active,
#header.header-white .navbar-mobile .dropdown>.dropdown-active {
    display: block;
}

/**
  * Nav Account 
  */
.nav-account,
#header.header-white .nav-account {
    background-color: transparent;
    margin-right    : 1rem;
}

/**
  * Header filter
  */
.header-filters {
    background-color: white;
    padding         : 0.5rem 0.1rem;
}

@media (max-width: 815px) {
    .header-filters {
        display: none !important;
    }
}

.header-filters .filters {
    background: #f7f7f7 !important;
    box-shadow: unset !important;
    padding   : 0 !important;
}

.header-filters .filters .filters-horizontal .filters-selects-prefix__control,
.header-filters .filters .filters-vertical .filters-selects-prefix__control,
.header-filters .filters .filters-horizontal .form-control,
.header-filters .filters .filters-vertical .form-control {
    padding: 0 !important;
}

@media (max-width: 1400px) {
    .header-filters .filters form button[type="submit"] span {
        display: none !important;
    }
}

@media (max-width: 815px) {
    .header-filters .filters {
        border-radius: unset;
        background   : transparent !important;
    }

    .header-filters .filters .filters-vertical .filters-selects-prefix__control,
    .header-filters .filters .filters-vertical .form-control,
    .header-filters .filters .filters-horizontal .filters-selects-prefix__control,
    .header-filters .filters .filters-horizontal .form-control {
        background-color: whitesmoke;
        padding         : 0 !important;
    }
}