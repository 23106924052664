/*
*******************************************
@File: Preloader Styles
*******************************************
*/
.preloader {
    min-height      : 100vh;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    font-size       : calc(10px + 2vmin);
    background-color: var(--tertiary-white);
}

.preloader .spinner {
    display         : flex;
    justify-content : center;
    align-items     : center;
    background-color: transparent;
}

.preloader .spinner-message {
    font-size       : 2rem;
    padding-right   : 1rem;
    color           : #979a9a;
}

.preloader .spinner-loader {
    width        : 30px;
    height       : 30px;
    border       : 2px solid var(--main-green);
    border-top   : 2px solid transparent;
    border-radius: 50%;
    animation    : spin 0.5s linear 0s infinite;
}

.preloader .spinner-completed {
    font-size: 2rem;
    color    : var(--main-green);
    animation: bigger 1s linear;
}

@keyframes bigger {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(2);
    }
}

@keyframes spin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}