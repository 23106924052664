/*
*******************************************
@File: Pubs Styles
*******************************************
*/
.ads {
    width           : 100%;
    background-color: transparent;
    display         : flex;
    align-items     : center;
    justify-content : center;
}

.ads .leaderboard {
    width           : 728px;
    height          : 90px;
    background-color: #eaecee;
}

.ads .x-leaderboard {
    width           : 970px;
    height          : 90px;
    background-color: #eaecee;
}

.ads .hugeboard {
    width           : 970px;
    height          : 250px;
    background-color: #eaecee;
}

.ads .inline {
    width           : 300px;
    height          : 250px;
    background-color: #eaecee;
}

.ads .rectangle {
    width           : 300px;
    height          : 280px;
    background-color: #eaecee;
}

.ads .interstitial {
    width           : 300px;
    height          : 480px;
    background-color: #eaecee;
}

.ads .half-page {
    width           : 300px;
    height          : 600px;
    background-color: #eaecee;
}

.ads .portrait {
    width           : 300px;
    height          : 1050px;
    background-color: #eaecee;
}