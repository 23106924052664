/*
*******************************************
@File: Cards Styles
*******************************************
*/
.card {
    position              : relative;
    display               : flex;
    -webkit-flex-direction: column;
    flex-direction        : column;
    min-width             : 0;
    word-wrap             : break-word;
    background-color      : #fff;
    background-clip       : border-box;
    border                : 0 solid #f6f6f6;
    border-radius         : .25rem;
    margin-bottom         : 24px;
    box-shadow            : 0 .75rem .5rem rgba(18, 38, 63, .03);
}