/*
*******************************************
@File: Sections Styles
*******************************************
*/
section {
    overflow: hidden;
}

.section-header h3 {
    font-size     : 22px;
    color         : #111;
    text-transform: uppercase;
    text-align    : center;
    font-weight   : 700;
    position      : relative;
    padding-bottom: 15px;
}

.section-header h3::before {
    content   : '';
    position  : absolute;
    display   : block;
    width     : 120px;
    height    : 1px;
    background: #ddd;
    bottom    : 1px;
    left      : calc(50% - 60px);
}

.section-header h3::after {
    content   : '';
    position  : absolute;
    display   : block;
    width     : 40px;
    height    : 3px;
    background: var(--tertiary-green);
    bottom    : 0;
    left      : calc(50% - 20px);
}

.section-header p {
    text-align    : center;
    padding-bottom: 30px;
    color         : #333;
}
.custom-section {
    border-radius: 6px;
    border-top: 1px solid var(--gray-6, #F2F2F2);
    border-right: 1px solid var(--gray-6, #F2F2F2);
    border-left: 1px solid var(--gray-6, #F2F2F2);
    background: linear-gradient(180deg, #FFF 0%, #FAFAFA 100%);
    box-shadow: 0px 0px 8px 0px rgba(50, 55, 57, 0.15);
    padding: 16px;
    margin-bottom: 16px;
}
.custom-section .title {
    color: #005073;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
}