/*
*******************************************
@File: Footer Styles
*******************************************
*/
#footer {
    background: #053854;
    padding   : 0 0 30px 0;
    color     : #eee;
    font-size : 14px;
}

#footer .footer-top {
    background: #0d3f61;
    padding   : 60px 0 30px 0;
}

#footer .footer-top .footer-info {
    margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
    font-size     : 34px;
    margin        : 0 0 20px 0;
    padding       : 2px 0 2px 10px;
    line-height   : 1;
    font-family   : "Montserrat", sans-serif;
    font-weight   : 700;
    letter-spacing: 3px;
    border-left   : 4px solid var(--tertiary-green);
}

#footer .footer-top .footer-info p {
    font-size    : 14px;
    line-height  : 24px;
    margin-bottom: 0;
    font-family  : "Montserrat", sans-serif;
    color        : #eee;
}

#footer .footer-top .social-links a {
    display        : inline-block;
    background     : rgba(51, 51, 51, 0.3);
    color          : #eee;
    line-height    : 1;
    margin-right   : 4px;
    border-radius  : 50%;
    width          : 36px;
    height         : 36px;
    transition     : 0.3s;
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
}

#footer .footer-top .social-links a i {
    line-height: 0;
    font-size  : 16px;
}

#footer .footer-top .social-links a:hover {
    background: var(--tertiary-green);
    color     : #fff;
}

#footer .footer-top h4 {
    font-size     : 14px;
    font-weight   : bold;
    color         : #fff;
    text-transform: uppercase;
    position      : relative;
    padding-bottom: 12px;
}

#footer .footer-top h4::before,
#footer .footer-top h4::after {
    content : '';
    position: absolute;
    left    : 0;
    bottom  : 0;
    height  : 2px;
}

#footer .footer-top h4::before {
    right     : 0;
    background: #555;
}

#footer .footer-top h4::after {
    background: var(--tertiary-green);
    width     : 60px;
}

#footer .footer-top .footer-line {
    height          : 1px;
    width           : 100%;
    background-color: #ddd;
    opacity         : 0.5;
    margin-top      : 15px;
    margin-bottom   : 15px;
}

#footer .footer-top .footer-socials {
    width          : 100%;
    height         : 100%;
    padding        : 0 30px;
    display        : flex;
    align-items    : center;
    justify-content: center;
}

#footer .footer-top .footer-socials .footer-contact {
    flex            : 1;
    height          : 100%;
    padding         : 20px;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    border-radius   : 4px;
    background-color: rgba(0, 49, 87, 0.5);
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding   : 0;
    margin    : 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 8px;
    color        : #ddd;
}

#footer .footer-top .footer-links ul li {
    display         : inline-block !important;
    padding         : 10px 0;
    margin          : 0 10px;
    background-color: transparent;
}

#footer .footer-top .footer-links ul a {
    color: #eee;
}

#footer .footer-top .footer-links ul a:hover {
    color: var(--tertiary-green);
}

#footer .copyright {
    text-align : center;
    padding-top: 30px;
}

#footer .credits {
    text-align: center;
    font-size : 13px;
    color     : #ddd;
}

#footer .credits a {
    color      : #ddd;
    font-weight: bolder;
}