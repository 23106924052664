/*
*******************************************
@File: Circular Progressbar Styles
*******************************************
*/
.profile-percentage {
    color: #0DA2E1;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.35px;
}
.profile-complete-percentage {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color:#534741;
    margin-bottom: 10px;
}
.profile-completed {
    color: #437B29;
}
.profile-camera {
    width: 26px;
    height: 26px;
    line-height: 24px;
    color: #ffffff;
    border-radius: 100%;
    background: #005073;
    text-align: center;
    border: 2px solid #ffffff;
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 1;
    cursor: pointer;
}
.user-profile-image {
    position: absolute;
    left: 0;
    top: 0;
    margin: auto;
    bottom: 0;
    right: 0;
}