.get-verified {
    margin-top: 24px;
    border-radius: 6px;
    background: var(--branco, #FFF);
    box-shadow: 2px 2px 16px 0px rgba(50, 55, 57, 0.15);
    padding: 24px;
}
.get-verified-text {
    color: var(--azul-escuro, #005073);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;   
}
.get-verified-btn {
    border-radius: 100px;
    background: var(--azul-escuro, #005073);
    border: 1px solid #005073;
    color: #FDFDFD;
    width: 100%;
    text-align: center;
    padding: 14px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.35px;
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
}
.get-verified-btn:disabled {
    background: var(--azul-escuro, #25ca6f);
    border-color: var(--azul-escuro, #25ca6f);
}
.get-verified-btn svg {right: 20px}

.verification-in-process .get-verified-text {
    color: var(--azul-escuro, #005073);
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
}
.verification-successfull h3 {
    color: #437B29;
    font-size: 40px;
    font-weight: 500;
    line-height: 45px;
}
.verification-successfull p {
    color: #534741;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 0;
    margin-top: 25px;   
}
.verification-expired {
    background: rgba(255, 253, 200, 0.27);
}
.verification-expired .get-verified-text {
    color: #534741;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
}