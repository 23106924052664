/*
*******************************************
@File: Breadcrumbs Styles
*******************************************
*/
.breadcrumb {
    padding         : 0.5rem 0.25rem;
    background-color: transparent;
    min-height      : 35px;
    margin-bottom   : 0;
}

.breadcrumb .breadcrumb-item,
.breadcrumb .breadcrumb-item.active {
    font-size: 12px;
}