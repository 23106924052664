/*
*******************************************
@File: Sidebar Styles
*******************************************
*/
.custom-sidebar {
    max-width: 250px;
    padding-right: 0;
    padding-left: 0;
}
.custom-sidebar-content {
    max-width: calc(100% - 270px);
}
.sidebar {
    width          : 100%;
    background     : transparent;
    min-height     : 500px;
    display        : flex;
    justify-content: center;
    flex-direction : column;
    /* padding-left   : 1rem;
    padding-right  : 1rem; */
    box-shadow: 0px 0px 8px 0px rgba(50, 55, 57, 0.15);
}

.sidebar-avatar {
    background-color: var(--main-blue);
    text-transform  : capitalize;
    font-size       : 3rem;
    font-weight     : 400;
    position        : absolute;
    width           : 106px;
    height          : 106px;
    top             : 7px;
    left            : 7px;
}


.sidebar-content {
    flex         : 1;
    /* margin-top   : 2rem; */
    margin-bottom: 1rem;
}

.sidebar-content ul,
.sidebar-foot ul {
    list-style      : none;
    padding         : 0;
    margin          : 0;
    background-color: transparent;
}

.sidebar-content ul li {
    background-color: transparent;
    /* margin-bottom   : 1rem; */
    border-top: 1px solid var(--gray-6, #F2F2F2);
    border-bottom: 1px solid var(--gray-6, #F2F2F2);
    /* margin-left     : 3.5rem;
    margin-right    : 3.5rem; */
}

.sidebar-content ul li:last-child {
    margin-bottom: 0;
}

.sidebar-content ul li a {
    background-color: transparent;
    /* text-align      : center; */
    /* flex-direction  : column; */
    display         : flex;
    align-items     : center;
    /* justify-content : center; */
    color           : #4F4F4F;
    padding         : 1.5rem 1rem;
    /* border-radius   : 7px; */
    font-size       : 16px;
    font-weight     : 500;
}

.sidebar-content ul li a i {
    font-size    : 1.5rem;
    /* margin-bottom: 5px; */
    margin-right: 10px;
}

.sidebar-content ul li a:hover,
.sidebar-content ul li a.active {
    background-color: var(--main-green);
    color           : white;
}

.sidebar-content ul li a:hover i,
.sidebar-content ul li a.active i {
    color: white;
}


.sidebar-foot .parent-useful-links ul {
    display        : flex;
    flex-wrap      : wrap;
    align-items    : center;
    justify-content: center;
}

.sidebar-foot .parent-useful-links ul li {
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.sidebar-foot .parent-useful-links ul li+li {
    padding-left: 10px;
}

.sidebar-foot .parent-useful-links ul li a {
    display    : inline-block;
    color      : #777777;
    font-size  : 13px;
    line-height: 2;
}