/*
*******************************************
@File: Paginator Styles
*******************************************
*/
.paginator {
    color: #024ed5;
}

.paginator ul {
    display   : flex;
    padding   : 0;
    margin    : 0;
    list-style: none;
}

.paginator li {
    margin    : 0 5px;
    transition: 0.3s;
}

.paginator li a {
    color          : #012970;
    padding        : 7px 16px;
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.paginator li.active,
.paginator li:hover {
    background: #4154f1;
}

.paginator li.active a,
.paginator li:hover a {
    color: #fff;
}