/*
*******************************************
@File: Accordion Styles
*******************************************
*/
.accordion-item {
    border       : 0;
    margin-bottom: 0.5rem;
}

.accordion-item:last-child {
    margin-bottom: 0;
}

.accordion-header {
    margin: 0;
}

.accordion-button {
    display         : flex;
    border-radius   : calc(.25rem - 1px);
    background-color: rgba(18, 207, 239, 0.12);
    padding         : 18px 20px;
    color           : #005073;
    font-weight     : 500;
    align-items     : center;
    justify-content : space-between;
}

.accordion-button:not(.collapsed) {
    color           : #005073;
    background-color: rgba(18, 207, 239, 0.12);
    box-shadow      : unset;
}

.accordion-button::after {
    background-size    : 1rem;
    background-position: center center;
    border-radius      : 50%;
    /* background-color   : #ffffff; */
    background-image   : url("../images/accordions/accordion-up.svg");
}

.accordion-button:not(.collapsed)::after {
    background-image: url("../images/accordions/accordion-up.svg");
}

.accordion-button:focus {
    border-color: transparent;
    box-shadow  : unset;
}