/*
*******************************************
@File: Square Styles
*******************************************
*/
.square {
    padding   : 20px;
    background: #ffffff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.square .square-title {
    font-size  : 18px;
    font-weight: 700;
    padding    : 0 0 0 0;
    margin     : 0 0 15px 0;
    color      : #012970;
    position   : relative;
}

.square .square-item {
    margin-bottom: 30px;
}

/**
  * Square Categories
  */
.square .square-categories ul {
    list-style: none;
    padding   : 0;
}

.square .square-categories ul li {
    display: inline-block;
}

.square .square-categories ul a {
    color     : #4154f1;
    font-size : 14px;
    padding   : 6px 14px;
    margin    : 0 6px 8px 0;
    border    : 1px solid #d7e6ff;
    display   : inline-block;
    transition: 0.3s;
}

.square .square-categories ul a:hover {
    color     : #fff;
    border    : 1px solid #4154f1;
    background: #4154f1;
}

.square .square-categories ul a span {
    padding-left: 5px;
    color       : #aaaaaa;
    font-size   : 14px;
}

.square .square-categories ul a:hover span {
    color: whitesmoke;
}

/**
  * Square recent links
  */
.square .square-recents .recent-item+.recent-item {
    margin-top: 15px;
}

.square .square-recents .recent-item img {
    width     : 80px;
    float     : left;
    object-fit: cover;
}

.square .square-recents .recent-item h4 {
    font-size    : 14px;
    margin-left  : 95px;
    margin-bottom: 2px;
    font-weight  : bold;
}

.square .square-recents .recent-item h4 a {
    color     : #012970;
    transition: 0.3s;
}

.square .square-recents .recent-item h4 a:hover {
    color: #4154f1;
}

.square .square-recents .recent-item time {
    display    : block;
    margin-left: 95px;
    font-style : italic;
    font-weight: 500;
    font-size  : 10px;
    color      : #aaaaaa;
}

/**
  * Square tags
  */
.square .square-tags {
    margin-bottom: -10px;
}

.square .square-tags ul {
    list-style: none;
    padding   : 0;
}

.square .square-tags ul li {
    display: inline-block;
}

.square .square-tags ul a {
    color     : #0257ee;
    font-size : 12px;
    padding   : 6px 14px;
    margin    : 0 6px 8px 0;
    border    : 1px solid #d7e6ff;
    display   : inline-block;
    transition: 0.3s;
}

.square .square-tags ul a:hover {
    color     : #fff;
    border    : 1px solid #0257ee;
    background: #0257ee;
}

.square .square-tags ul a span {
    padding-left: 5px;
    color       : #a5c5fe;
    font-size   : 14px;
}

.square .square-tags ul a:hover span {
    color: whitesmoke;
}