/*
*******************************************
@File: Maps Styles
*******************************************
*/
#mapid {
    height: 500px;
}

/*
** Clusters 
*/
.custom-cluster {
    background   : var(--secondary-blue);
    border       : 3px solid #ededed;
    border-radius: 50%;
    color        : #ededed;
    height       : 40px;
    line-height  : 37px;
    text-align   : center;
    width        : 40px;
}

/*
** Popups 
*/
.popup-box {
    background-color: transparent;
}

.popup-box .popup-content {
    width                  : 100%;
    height                 : 130px;
    object-fit             : cover;
    border-top-left-radius : 12px;
    border-top-right-radius: 12px;
}