/*
*******************************************
@File: Badges Styles
*******************************************
*/
.badge {
    display       : inline-block;
    padding       : 0.25em 0.4em;
    font-size     : 75%;
    font-weight   : 500;
    line-height   : 1;
    color         : #fff;
    text-align    : center;
    white-space   : nowrap;
    vertical-align: baseline;
    border-radius : 0.25rem;
}

.badge-rounded {
    padding-right: .6em;
    padding-left : .6em;
    border-radius: 50rem !important;
}

.badge-success {
    background-color: #34c38f !important;
}

.bg-warning {
    background-color: #f1b44c !important;
}

.badge-danger {
    background-color: #f46a6a !important;
}

.badge-secondary {
    background-color: #74788d !important;
}

.badge-primary {
    background-color: #556ee6 !important;
}

.badge-info {
    background-color: #50a5f1 !important;
}

.badge-dark {
    color           : #eff2f7;
    background-color: #343a40 !important;
}

.badge-soft-success {
    color           : #34c38f;
    background-color: rgba(52, 195, 143, 0.18);
}

.badge-soft-warning {
    color           : #f1b44c;
    background-color: rgba(241, 180, 76, 0.18);
}

.badge-soft-danger {
    color           : #f46a6a;
    background-color: rgba(244, 106, 106, 0.18);
}

.badge-soft-secondary {
    color           : #74788d;
    background-color: rgba(116, 120, 141, 0.18);
}

.badge-soft-primary {
    color           : #556ee6;
    background-color: rgba(85, 110, 230, 0.18);
}

.badge-soft-info {
    color           : #50a5f1;
    background-color: rgba(80, 165, 241, 0.18);
}

.badge-soft-dark {
    color           : #343a40;
    background-color: rgba(52, 58, 64, 0.18);
}