/*
*******************************************
@File: Carousels Styles
*******************************************
*/
.carousels {
    height          : 100vh;
    width           : 100%;
    background-color: transparent;
}

.carousels .carousel.carousel-slider {
    height: 100vh;
    width : 100%;
}

.carousels .carousel .slide {
    height: 100vh;
    width : 100%;
}

.carousels .carousel .carousel-status {
    position   : absolute;
    top        : 0.1rem;
    right      : 0.1rem;
    padding    : 5px;
    font-size  : 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color      : #fff;
}

.carousels .carousels-item {
    position        : absolute;
    display         : block;
    height          : 100%;
    width           : 100%;
    background-color: transparent;
}

.carousels .carousels-item::after {
    content         : '';
    position        : absolute;
    height          : 100vh;
    width           : 100%;
    right           : 0;
    bottom          : 0;
    left            : 0;
    top             : 0;
    opacity         : 0.6;
    background-color: #000;
}

.carousels .carousels-item img, video {
    height    : 100%;
    width     : 100%;
    object-fit: cover;
}

.carousels .carousel .control-dots {
    position  : absolute;
    bottom    : 0;
    margin    : 10px 0;
    padding   : 0 10px;
    text-align: center;
    z-index   : 12;
    width     : 100%;
    background: transparent;
}

.carousels .carousel .control-dots .control-dot {
    display         : inline-block;
    height          : 12px;
    width           : 12px;
    border-radius   : 50%;
    cursor          : pointer;
    margin-right    : 0.2rem;
    margin-left     : 0.2rem;
    background-color: rgba(37, 202, 111, 0.15);
    transition      : all 1s ease-in-out;
}

.carousels .carousel .control-dots .control-dot.active {
    background-color: rgba(37, 202, 111, 0.95);
    width           : 24px;
    border-radius   : 50px;
}

.carousels .carousel .control-dots .control-dot:hover {
    background-color: rgba(37, 202, 111, 0.75);
}

.carousels .carousel .control-arrows {
    position       : absolute;
    bottom         : 50%;
    margin         : 10px 0;
    z-index        : 12;
    width          : 35px;
    height         : 35px;
    color          : #fff;
    cursor         : pointer;
    background     : rgba(37, 202, 111, 0.15);
    font-size      : 1.2rem;
    border-radius  : 50px;
    padding        : 10px;
    user-select    : none;
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
    transition     : all 1s ease-in-out;
}

.carousels .carousel .control-arrows:hover {
    background: rgba(37, 202, 111, 0.95);
}

.carousels .carousel .control-arrows.control-next {
    right: 1rem;
}

.carousels .carousel .control-arrows.control-prev {
    left: 1rem;
}