/*
*******************************************
@File: Buttons Styles
*******************************************
*/
.btn-discover {
    background   : var(--secondary-blue);
    padding      : 0.5rem 1rem;
    color        : #fff;
    cursor       : pointer;
    border-radius: var(--button-radius);
    border       : none;
}

.btn-discover:hover {
    color     : #fff;
    background: var(--main-blue);
}

.btn-more {
    display      : inline-block;
    color        : var(--dark-blue);
    font-size    : 14px;
    font-weight  : 400;
    font-family  : 'Montserrat', sans-serif;
    font-weight  : 600;
    border       : 1px solid var(--dark-blue);
    padding      : 5px 15px;
    border-radius: 4px;
    transition   : 0.3s;
}

.btn-more:hover {
    background: var(--secondary-blue);
    color     : #fff;
}

.btn-contact {
    display         : inline-block;
    color           : #FFFFFF;
    background-color: var(--main-blue);
    border          : 1px solid var(--dark-blue);
    border-radius   : 8px;
    padding         : 10px 20px;
}

.btn-contact span {
    color      : #FFFFFF;
    font-size  : 17px;
    line-height: 34px;
    text-shadow: none;
}

.btn-contact:hover {
    background-color: var(--secondary-blue);
}

.btn-submit {
    color         : #fff;
    border-radius : 5px;
    font-size     : 16px;
    padding       : 10px 20px;
    outline       : 0;
    text-transform: none;
    box-shadow    : 0 -4px 0 0 rgba(0, 0, 0, 0.14) inset;
    border        : none;
}

.btn-mailer {
    background: var(--secondary-green);
}

.btn-mailer:hover {
    background-color: var(--tertiary-green);
}


.btn-commenter {
    background: var(--dark-blue);
}

.btn-commenter:hover {
    background-color: var(--secondary-blue);
}

.btn-leave-comment, .btn-apply {
    border       : none;
    color        : var(--secondary-white);
    background   : var(--secondary-blue);
    width        : auto;
    height       : auto;
    padding      : 10px 15px;
    border-radius: 4px;
    font-size    : 16px;
    font-weight  : 400;
    line-height  : 1;
}

.btn-leave-comment:hover, .btn-apply:hover {
    background-color: var(--dark-blue);
    color           : var(--main-white);
}

.btn-apply {
    position: sticky;
}

.btn-apply-fixed {
    z-index : 1000;
    position: fixed;
    right    : 0;
}