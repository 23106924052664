/**
* App Name: ACE Orientation Web
* App URL: 
* Author: Logistify
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin         : 0;
    padding        : 0;
    text-decoration: none;
    box-sizing     : border-box;
}

:root {
    --logo-light-blue : #12cfef;
    --logo-dark-blue  : #003157;
    --logo-dark-green : #6DBE4A;
    --logo-light-green: #B9EB30;
    --dark-blue       : #053854;
    --main-blue       : #0d3f61;
    --secondary-blue  : #005073;
    --main-green      : #61ce70;
    --secondary-green : #25ca6f;
    --tertiary-green  : #18d26e;
    --main-black      : #212121;
    --main-white      : #ffffff;
    --secondary-white : #f7f7f7;
    --tertiary-white  : #f8f8fb;
    --dark-grey       : #808080;
    --main-grey       : #c2c3c5;
    --secondary-grey  : #b2b2b2;
    --button-radius   : 4px;
    --banner-height   : 40vh;
    --sidebar-width   : 250px;
}

body {
    color           : #212121;
    font-family     : 'Montserrat', sans-serif;
    overflow-x      : hidden !important;
    background-color: #f8f9f9;
    font-size       : 14px;
}

a {
    color          : var(--tertiary-green);
    transition     : 0.5s;
    text-decoration: none;
}

a:hover,
a:active,
a:focus {
    color          : var(--secondary-green);
    outline        : none;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family  : "Montserrat", sans-serif;
    font-weight  : 400;
    margin-bottom: 20px;
}

.cursor-hand {
    cursor: pointer !important;
}

.center-box {
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.center-ibox {
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
}

.bg-blue {
    background-color: var(--dark-blue) !important;
}

.bg-green {
    background-color: var(--main-green) !important;
}

.bg-facebook {
    background-color: #4b69b0 !important;
}

.bg-whatsapp {
    background-color: #25d366 !important;
}

.bg-instagram {
    background-color: #e92a77 !important;
}

.bg-twitter {
    background-color: #1c9ceb !important;
}

.bg-youtube {
    background-color: #ff0000 !important;
}

.bg-telegram {
    background-color: #27a7e8 !important;
}

.text-blue {
    color: var(--dark-blue) !important;
}

.text-green {
    color: var(--main-green) !important;
}


/*--------------------------------------------------------------
# Reviews Styles
--------------------------------------------------------------*/
.reviews .reviews-actions {
    display       : flex;
    flex-direction: row;
    align-items   : center;
}

.reviews .reviews-actions i {
    font-size    : 20px;
    padding      : 5px;
    cursor       : pointer;
    color        : #626567;
    border       : 1px solid #626567;
    border-radius: 50%;
}

.reviews .reviews-actions i:hover {
    color : var(--secondary-green);
    border: 1px solid var(--secondary-green);
}

.reviews .reviews-stars {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: flex-end;
}

.reviews .reviews-stars i {
    font-size: 20px;
}


/*--------------------------------------------------------------
# Box Single Styles
--------------------------------------------------------------*/
.box-single {
    padding         : 20px;
    background-color: #ffffff;
    margin-bottom   : 15px;
}

.single-shadow {
    box-shadow: 0px 5px 27.52px 4.48px rgba(0, 0, 0, 0.05);
}

.box-single .single-title {
    padding      : 0;
    margin-bottom: 20px;
    font-size    : 22px;
    font-weight  : bold;
    color        : var(--logo-dark-blue);
}

.box-single .single-title a {
    color     : var(--logo-dark-blue);
    transition: 0.3s;
}

.box-single .single-title a:hover {
    color: var(--secondary-blue);
}

.box-single .single-meta {
    padding: 5px;
}

.box-single .single-meta.meta-row {
    padding: 10px;
}

.box-single .single-meta ul {
    list-style: none;
    padding   : 0;
    margin    : 0;
}

.box-single .single-meta.meta-row ul {
    flex-wrap      : wrap;
    align-items    : center;
    justify-content: space-between;
}

.box-single .single-meta ul li {
    display      : flex;
    margin-bottom: 15px;
}

.box-single .single-meta.meta-row ul li {
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-left    : 15px;
}

.box-single .single-meta ul li:last-child {
    margin-bottom: 0;
}

.box-single .single-meta.meta-row ul li:last-child {
    margin-left: 0;
}

.box-single .single-meta a i,
.box-single .single-meta span i {
    color       : var(--dark-grey);
    font-size   : 15px;
    margin-right: 5px;
}

.box-single .single-meta a img,
.box-single .single-meta span img {
    margin-right: 5px;
}

.box-single .single-meta.meta-row a i {
    color      : var(--secondary-blue);
    line-height: 0;
}

.box-single .single-meta a,
.box-single .single-meta span {
    display        : flex;
    align-items    : center;
    justify-content: center;
    color          : var(--dark-grey);
    font-size      : 14px;
}

.box-single .single-meta a span {
    display: inline-block;
}

.box-single .single-meta.meta-row a,
.box-single .single-meta.meta-row span {
    line-height: 1;
}

.box-single .single-meta a:hover,
.box-single .single-meta a:hover i {
    color: var(--secondary-green);
}

.box-single .single-meta.meta-row a:hover {
    color: var(--logo-dark-blue);
}

.box-single .single-badges {
    padding-bottom : 5px;
    padding-top    : 5px;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
}

.box-single .single-badges ul {
    margin : 0;
    padding: 0;
}

.box-single .single-badges ul li+li {
    margin-left: 5px;
}

.box-single .single-content {
    padding-top   : 20px;
    padding-bottom: 20px;
}

.box-single .single-content p {
    line-height: 24px;
}

.box-single .single-content h3 {
    font-size    : 20px;
    margin-bottom: 15px;
    font-weight  : bold;
}

.box-single .single-content blockquote {
    overflow        : hidden;
    background-color: rgba(1, 41, 112, 0.05);
    padding         : 20px;
    position        : relative;
    margin          : 10px 0;
}

.box-single .single-content blockquote p {
    color        : #444444;
    line-height  : 1.5;
    margin-bottom: 0;
    font-style   : italic;
    font-weight  : 500;
    font-size    : 18px;
}

.box-single .single-content blockquote::after {
    content         : '';
    position        : absolute;
    left            : 0;
    top             : 0;
    bottom          : 0;
    width           : 3px;
    background-color: var(--logo-dark-blue);
}

.box-single .single-footer {
    padding-top: 10px;
    border-top : 0.5px solid #e5e7e9;
}

.box-single .single-footer i {
    color  : var(--secondary-blue);
    display: inline;
}

.box-single .single-footer a,
.box-single .single-footer span {
    color     : var(--main-blue);
    transition: 0.3s;
}

.box-single .single-footer a:hover {
    color: var(--dark-blue);
}

.box-single .single-footer ul {
    list-style: none;
    display   : inline;
    padding   : 0;
    font-size : 12px;
}

.box-single .single-footer ul li {
    display: inline-block;
}

.box-single .single-footer ul li+li::before {
    padding-right: 6px;
    color        : #6c757d;
    content      : ",";
}

.box-single .single-footer .share {
    font-size: 16px;
}

.box-single .single-footer .share i {
    padding-left: 5px;
}

.box-single .single-medias {
    width         : 100%;
    padding-bottom: 20px;
}

.box-single .single-medias img {
    width     : 100%;
    max-height: 200px;
    object-fit: cover;
}

.box-single .single-author {
    margin-top   : 15px;
    margin-bottom: 5px;
}

.box-single .single-author img {
    width       : 120px;
    margin-right: 20px;
}

.box-single .single-author h4 {
    font-weight  : 600;
    font-size    : 22px;
    margin-bottom: 0px;
    padding      : 0;
    color        : var(--logo-dark-blue);
}

.box-single .single-author .social-links {
    margin: 0 10px 10px 0;
}

.box-single .single-author .social-links a {
    color       : rgba(1, 41, 112, 0.5);
    margin-right: 5px;
}

.box-single .single-author p {
    font-style: italic;
    color     : #b7b7b7;
    font-size : 13px;
}


/*--------------------------------------------------------------
# Box Item Styles
--------------------------------------------------------------*/
.box-item {
    height          : 500px;
    padding         : 30px;
    border-radius   : 5px;
    transition      : 0.3s;
    box-shadow      : 0px 0px 5px rgb(0 0 0 / 25%);
    border          : 2px solid var(--dark-blue);
    background-color: white;
    overflow        : hidden;
    display         : flex;
    flex-direction  : column;
}

.box-item .box-image {
    max-height             : 440px;
    border-top-left-radius : 5px;
    border-top-right-radius: 5px;
    margin                 : 0;
    margin-bottom          : 20px;
    overflow               : hidden;
}

.box-item .box-image img {
    transition: 0.5s;
    object-fit: cover;
}

.box-item .box-title h2 {
    font-size  : 24px;
    font-weight: bold;
    padding    : 0;
    margin     : 0 0 20px 0;
    transition : 0.3s;
    color      : var(--logo-dark-blue);
}

.box-item .box-title h3 {
    font-size  : 22px;
    margin     : 5px 0;
    font-weight: bold;
}

.box-item .box-title h5 {
    background-color: transparent;
    margin-bottom   : 0;
}

.box-item .box-title a {
    color     : var(--logo-dark-blue);
    transition: 0.3s;
}

.box-item .box-title a:hover {
    color: var(--secondary-blue);
}

.box-item .box-badges {
    margin-bottom   : 20px;
    color           : #4084fd;
    background-color: transparent;
}

.box-item .box-badges ul {
    display    : flex;
    flex-wrap  : wrap;
    list-style : none;
    align-items: center;
    padding    : 0;
    margin     : 0;
}

.box-item .box-badges ul li+li {
    margin-left: 10px;
}

.box-item .box-badges i {
    font-size   : 16px;
    margin-right: 8px;
    line-height : 0;
}

.box-item .box-badges a {
    color      : #777777;
    font-size  : 14px;
    display    : inline-block;
    line-height: 1;
}

.box-item .box-meta {
    margin-top      : 20px;
    margin-bottom   : 15px;
    background-color: transparent;
}

.box-item .box-meta ul {
    list-style: none;
    padding   : 0;
    margin    : 0;
}

.box-item .box-meta ul li {
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.box-item .box-meta ul li+li {
    padding-left: 15px;
}

.box-item .box-meta i {
    color       : #4084fd;
    font-size   : 15px;
    margin-right: 5px;
    line-height : 0;
}

.box-item .box-meta a {
    display        : flex;
    align-items    : center;
    justify-content: center;
    color          : #777777;
    font-size      : 14px;
    line-height    : 1;
}

.box-item .box-meta a span {
    display    : inline-block;
    color      : #777777;
    font-size  : 14px;
    line-height: 1;
}

.box-item .box-content {
    background-color: transparent;
    flex            : 1;
}

.box-item .box-links .read-more {
    -moz-text-align-last: right;
    text-align-last     : right;
}

.box-item .box-links .read-more a {
    display      : inline-block;
    background   : var(--secondary-blue);
    color        : #fff;
    padding      : 6px 10px;
    transition   : 0.3s;
    font-size    : 14px;
    border-radius: 4px;
}

.box-item .box-links .read-more a:hover {
    background: #5969f3;
}

.box-item .box-links .read-more a:hover .box-item .box-title {
    color: var(--secondary-blue);
}

.box-item .box-links .read-more a:hover .box-item .box-image img {
    transform: rotate(6deg) scale(1.2);
}

.box-item .box-links .official-link {
    -moz-text-align-last: left;
    text-align-last     : left;
}

.box-item .box-links .official-link a {
    display      : inline-block;
    border       : 1px solid var(--secondary-blue);
    color        : var(--secondary-blue);
    padding      : 6px 10px;
    transition   : 0.3s;
    font-size    : 14px;
    border-radius: 4px;
}

.box-item .box-links .official-link a:hover {
    border       : 1px solid var(--secondary-blue);
    color        : var(--secondary-blue);
    border-radius: 4px;
}

.box-item .box-links .see-more {
    display         : inline-flex;
    align-items     : center;
    justify-content : center;
    background-color: var(--secondary-blue);
    color           : whitesmoke;
    padding         : 6px 10px;
    transition      : 0.3s;
    font-size       : 14px;
    border-radius   : 4px;
}

.box-item .box-links .see-more:hover {
    background-color: var(--main-blue);
    color           : white;
}

.box-item .box-links .see-more i {
    line-height: 0;
    margin-left: 2px;
    font-size  : 18px;
    transition : 0.5s;
}

.box-item .box-links .see-more:hover i {
    transform: translateX(7px);
}

.box-item .box-actions {
    flex          : 1;
    display       : flex;
    flex-direction: row;
    align-items   : center;
}

.box-item .box-actions i {
    font-size    : 20px;
    padding      : 5px;
    cursor       : pointer;
    color        : #626567;
    border       : 1px solid #626567;
    border-radius: 50%;
}

.box-item .box-actions i:hover {
    color : var(--secondary-green);
    border: 1px solid var(--secondary-green);
}

.box-item .box-featured {
    width     : 200px;
    position  : absolute;
    top       : 18px;
    right     : -68px;
    transform : rotate(45deg);
    font-size : 14px;
    padding   : 1px 0 3px 0;
    background: #08da4e;
    color     : #fff;
}

.box-item .box-flag {
    background-repeat: no-repeat;
    background-size  : 100%;
    height           : 15px;
    width            : 20px;
    margin-left      : 5px;
    display          : inline-block;
}



/*--------------------------------------------------------------
# Home Styles
--------------------------------------------------------------*/
/*
** Carrousel 
*/
.home-carousel.carousels .carousel .control-dots {
    bottom    : 0;
    text-align: unset;
    width     : fit-content;
}

.home-carousel.carousels .carousel .control-arrows {
    bottom: 0;
}

.home-carousel.carousels .carousel .control-arrows.control-next {
    right: 1rem;
}

.home-carousel.carousels .carousel .control-arrows.control-prev {
    left : unset;
    right: 3.5rem;
}

/*
** Vision 
*/
.visions .box {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: space-between;
}

.visions .box h4 {
    margin-top   : 1rem !important;
    margin-bottom: 1rem !important;
}

.visions .box p {
    margin: 0 1rem !important;
}

.visions .box img {
    height          : 100px;
    width           : 100px;
    padding         : 5px;
    border-radius   : 2px;
    background-color: whitesmoke;
    box-shadow      : 0px 0 5px rgba(255, 255, 255, 0.30);
    transition      : 0.5s;
    transform       : scale(1.1);
}

.visions .box:hover img {
    transform : scale(1);
    transition: 0.5s;
    box-shadow: 0px 0 30px rgba(255, 255, 255, 0.30);
}

/*
** Establishment
*/
.establishments-sliders {
    background-color: transparent;
    padding         : 1rem !important;
    margin-top      : 2rem !important;
}

.establishments-sliders .box {
    height          : 170px;
    display         : flex;
    align-items     : center;
    justify-content : center;
    padding         : 2px;
    border-radius   : 2px;
    background-color: transparent;
}

.establishments-sliders .box a {
    height          : 100%;
    width           : 100%;
    display         : flex;
    align-items     : center;
    justify-content : center;
    background-color: transparent;
}

.establishments-sliders .box img {
    height: 100%;
    width : 100%;
}

/*
** Community 
*/
.community .community-wrapper {
    width           : 100%;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    background-color: transparent;
}

.community .community-map {
    background-color: transparent;
}

.community .community-counter {
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    margin          : 1px;
    background-color: transparent;
}

.community .community-size {
    font-size  : 3rem;
    color      : #ffffff;
    line-height: 1;
}

.community .community-label span {
    color: whitesmoke;
}

/*
** Members 
*/
.members .box {
    box-sizing    : content-box;
    padding       : 30px;
    margin        : 40px 30px;
    box-shadow    : 0px 0 20px rgba(1, 41, 112, 0.1);
    background    : #fff;
    min-height    : 320px;
    display       : flex;
    flex-direction: column;
    text-align    : center;
    transition    : 0.3s;
    border-radius : 5px;
}

.members .box .box-image {
    width        : 90px;
    border-radius: 50%;
    border       : 4px solid #fff;
    margin       : 0 auto;
}

.members .box h3 {
    font-size  : 18px;
    font-weight: bold;
    margin     : 10px 0 5px 0;
    color      : #111;
}

.members .box h4 {
    font-size: 14px;
    color    : #999;
    margin   : 0;
}

.members .box p {
    font-style: italic;
    margin    : 0 auto 15px auto;
}

/*
** CTA 
*/
#cta {
    background     : url("../images/bg-cta.jpg") center top no-repeat fixed;
    background-size: cover;
    padding        : 7rem 0;
    position       : relative;
}

#cta::before {
    content   : '';
    position  : absolute;
    left      : 0;
    right     : 0;
    top       : 0;
    bottom    : 0;
    background: rgba(0, 0, 0, 0.7);
    z-index   : 9;
}

#cta .container {
    position: relative;
    z-index : 10;
}

#cta p {
    color      : #fff;
    font-weight: 700;
    font-size  : large;
}

#cta .btn-cta {
    font-family   : "Montserrat", sans-serif;
    font-weight   : 500;
    font-size     : 16px;
    letter-spacing: 1px;
    display       : inline-block;
    padding       : 8px 28px;
    transition    : 0.5s;
    margin-top    : 10px;
    color         : #fff;
    background    : #61ce70;
    border-radius : 5px;
    border        : 1px solid #61ce70;
}

#cta .btn-cta:hover {
    color     : #61ce70;
    background: transparent;
    border    : 1px solid #61ce70;
}


/*--------------------------------------------------------------
# Domain Styles
--------------------------------------------------------------*/
/*
** List 
*/
.domains ul {
    list-style: none;
    padding   : 0;
    margin    : 0;
}

.domains ul li {
    display         : inline-flex;
    vertical-align  : top;
    width           : 20%;
    height          : 200px;
    padding         : 1px;
    background-color: transparent;
}

.domains ul li:hover {
    transition      : all 0.2s ease;
    width           : 30%;
    background-color: var(--main-green);
}

.domains ul li.no-animate:hover {
    transition      : none;
    width           : 20%;
    background-color: var(--main-green);
}

.domains ul li a {
    height          : 100%;
    width           : 100%;
    padding         : 0 10px;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    background-color: transparent;
}

.domains ul li a img {
    height: 65px;
    width : 65px;
}

.domains ul li h4 {
    text-align: center;
    margin    : 5px 0 5px 0;
    font-size : 18px;
    color     : #fff;
    margin-top: 1rem;
}

.domains ul li p {
    display      : none;
    color        : #fff;
    font-size    : 14px;
    text-align   : center;
    margin-bottom: 0;
}

.domains ul li:hover p {
    display: block;
}

.domains .box {
    text-align: center;
    background: transparent;
    min-width : 100%;
    padding   : 80px 20px;
    transition: all ease-in-out 0.3s;
}

.domains .box h4 {
    margin-top   : 20px;
    margin-bottom: 10px;
    margin-right : 10px;
    margin-left  : 10px;
    font-size    : 24px;
}

.domains .box h4 a {
    color: whitesmoke;
}

.domains .box p {
    display      : none;
    line-height  : 24px;
    font-size    : 14px;
    margin-bottom: 0;
}

.domains .box:hover {
    background  : #25ca6f;
    border-color: #25ca6f;
}

.domains .box:hover h4 a {
    color: #fff;
}

.domains .box:hover p {
    display: block;
    color  : #fff;
}

.domains .box img {
    height    : 90px;
    width     : 90px;
    transition: 0.5s;
    transform : scale(1.1);
}

.domains .box:hover img {
    transform: scale(1);
}

/*
** Details 
*/
#domain .box-single .domain-formations h4 {
    text-align: left !important;
}

#domain .square .square-recents .recent-item h4,
#domain .square .square-recents .recent-item time {
    background-color: transparent;
}


/*--------------------------------------------------------------
# Schools
--------------------------------------------------------------*/
/*
** List 
*/
.schools .box {
    height        : 430px;
    padding       : 30px;
    background    : #fff;
    text-align    : center;
    position      : relative;
    border-radius : 5px;
    box-shadow    : 0px 0px 5px rgb(0 0 0 / 25%);
    overflow      : hidden;
    border        : 2px solid var(--dark-blue);
    display       : flex;
    flex-direction: column;
}

.schools .featured {
    width     : 200px;
    position  : absolute;
    top       : 18px;
    right     : -68px;
    transform : rotate(45deg);
    font-size : 14px;
    padding   : 1px 0 3px 0;
    background: var(--tertiary-green);
    color     : #fff;
}

.schools .box .box-score {
    display       : flex;
    flex-direction: row;
    align-items   : center;
}

.schools .box .box-score .box-actions {
    flex          : 1;
    display       : flex;
    flex-direction: row;
    align-items   : center;
}

.schools .box .box-score .box-actions i {
    font-size    : 20px;
    padding      : 5px;
    cursor       : pointer;
    color        : #626567;
    border       : 1px solid #626567;
    border-radius: 50%;
}

.schools .box .box-score .box-actions i:hover {
    color : var(--secondary-green);
    border: 1px solid var(--secondary-green);
}

.schools .box .box-score .box-reviews {
    flex           : 1;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: flex-end;
}

.schools .box .box-score .box-reviews i {
    font-size: 20px;
}

.schools .box .box-content {
    flex: 1;
}

.schools .box .box-data {
    display       : flex;
    flex-direction: row;
    align-items   : center;
}

.schools .box .box-data .box-name {
    display        : flex;
    flex-direction : column;
    align-items    : flex-start;
    justify-content: flex-start;
    margin-right   : 5px;
    padding        : 5px;
}

.schools .box .box-data .box-name h3 {
    font-size : 20px;
    text-align: left;
    margin    : 0;
}

.schools .box .box-data .box-name .box-country {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
}

.schools .box .box-meta {
    background-color: transparent;
}

.schools .box .box-meta ul {
    list-style: none;
    padding   : 0;
    margin    : 0;
}

.schools .box .box-meta ul li {
    display      : flex;
    margin-bottom: 15px;
}

.schools .box .box-meta ul li a i,
.schools .box .box-meta ul li span i {
    color       : #777777;
    font-size   : 20px;
    margin-right: 5px;
    line-height : 20px;
}

.schools .box .box-meta ul li a,
.schools .box .box-meta ul li span {
    display        : flex;
    align-items    : center;
    justify-content: center;
    color          : #777777;
    font-size      : 15px;
    line-height    : 24px;
}

/* .schools .box .box-meta ul li a,
.schools .box .box-meta ul li span {
    color      : #777777;
    font-size  : 15px;
    line-height: 24px;
} */

.schools .box .box-meta ul li a img,
.schools .box .box-meta ul li span img {
    margin-right: 5px;
}

/* .schools .box .box-data .box-name .box-country .box-flag,
.school-country .country-flag {
    background-repeat: no-repeat;
    background-size  : 100%;
    height           : 15px;
    width            : 20px;
    margin-left      : 5px;
    display          : inline-block;
} */

.schools .box .box-more {
    display        : flex;
    align-items    : center;
    justify-content: flex-end;
}

.schools .box .box-more .see-more {
    display         : inline-flex;
    align-items     : center;
    justify-content : center;
    background-color: var(--secondary-blue);
    color           : whitesmoke;
    padding         : 6px 10px;
    transition      : 0.3s;
    font-size       : 14px;
    border-radius   : 4px;
}

.schools .box .box-more .see-more:hover {
    background-color: var(--main-blue);
    color           : white;
}

.schools .box .box-more .see-more i {
    line-height: 0;
    margin-left: 2px;
    font-size  : 18px;
    transition : 0.5s;
}

.schools .box .box-more .see-more:hover i {
    transform: translateX(7px);
}


/*
** Details 
*/
#school {
    background-color: whitesmoke !important;
}

#school .box-single {
    padding: 40px 0;
}

#school .section-shadow {
    box-shadow: 0px 5px 27.52px 4.48px rgba(0, 0, 0, 0.05);
}

/* #school .school-top {
    display         : flex;
    flex-direction  : row;
    background-color: transparent;
}

#school .school-top .school-meta {
    flex            : 1;
    background-color: transparent;
}

#school .school-top .school-meta h3 {
    font-size  : 24px;
    font-weight: 500;
}

#school .school-top .reviews .reviews-actions {
    justify-content: flex-end;
    margin-top     : 5px;
    background-color: tomato;
} */
#formation .top-section .reviews .reviews-actions i,
#school .top-section .reviews .reviews-actions i {
    font-size    : 17px;
    padding      : 0;
    cursor       : default;
    color        : var(--dark-grey);
    border       : unset;
    border-radius: unset;
}

#formation .top-section .reviews .reviews-actions span,
#school .top-section .reviews .reviews-actions span {
    font-size    : 13px;
    cursor       : pointer;
    color        : var(--dark-grey);
    border       : 1px solid var(--dark-grey);
    border-radius: 5px;
}

#formation .top-section .reviews .reviews-actions span:hover,
#school .top-section .reviews .reviews-actions span:hover {
    color       : var(--tertiary-green);
    border-color: var(--tertiary-green);
}

#formation .top-section .reviews .reviews-actions span:hover i,
#school .top-section .reviews .reviews-actions span:hover i {
    color: var(--tertiary-green);
}


/* Same Country Schools */
#school .schools-suggestions .suggestions-sliders {
    background-color: transparent;
    padding         : 0;
    margin-top      : 1rem !important;
}

#school .schools-suggestions .suggestions-sliders .box {
    height          : 120px;
    display         : flex;
    align-items     : center;
    justify-content : center;
    padding         : 5px 10px;
    box-shadow      : 0px 5px 14.52px 4.48px rgba(0, 0, 0, 0.05);
    background-color: transparent;
    border          : 1px solid rgba(5, 56, 84, 0.4);
    border-radius   : 3px;
}

#school .schools-suggestions .suggestions-sliders .box a {
    height          : 100%;
    width           : 100%;
    display         : flex;
    align-items     : center;
    justify-content : center;
    background-color: transparent;
    color           : #333333;
}

#school .schools-suggestions .suggestions-sliders .box a:hover {
    color: var(--secondary-blue);
}

#school .schools-suggestions .suggestions-sliders .box a img {
    width       : 70px;
    height      : 70px;
    margin-right: 2px;
}

#school .schools-suggestions .suggestions-sliders .box a h5 {
    line-height   : 20px;
    font-size     : 20px;
    margin-bottom : 0;
    font-style    : normal;
    letter-spacing: 0.5px;
    font-weight   : 500;
}


/* School Socials Networks */
#school .school-socials ul {
    list-style      : none;
    padding         : 0;
    margin          : 0;
    display         : flex;
    flex-wrap       : wrap;
    align-items     : center;
    justify-content : space-between;
    background-color: transparent;
}

#school .school-socials ul li {
    width        : 35px;
    height       : 35px;
    border-radius: 50%;
    margin       : 3px;
}

#school .school-socials ul li a {
    width          : 100%;
    height         : 100%;
    display        : flex;
    align-items    : center;
    justify-content: center;
    color          : white;
}

#school .school-socials ul li a i {
    font-size: 20px;
}


/* School Custom */
#school .country-others-schools h4,
#school .comments-section h4 {
    font-weight: 500;
}


/* School Foreign List */
#school .foreigns-section ul {
    list-style : none;
    padding    : 0;
    margin     : 0;
    display    : flex;
    flex-wrap  : wrap;
    align-items: center;
}

#school .foreigns-section ul li {
    width           : 25%;
    background-color: transparent;
    display         : flex;
    align-items     : center;
    justify-content : center;
    padding         : 7px 2px;
}

#school .foreigns-section ul li img {
    width : 100%;
    height: 45px;
}

#formation .accordion-body,
#school .accordion-body {
    padding: 1rem 0;
}

#formation .accordion.content-accordion .accordion-button,
#school .accordion.content-accordion .accordion-button {
    background-color: var(--main-blue);
    color           : var(--main-white);
}

#formation .accordion.content-accordion .accordion-button:not(.collapsed)
#school .accordion.content-accordion .accordion-button:not(.collapsed) {
    color           : var(--secondary-white);
    background-color: var(--secondary-blue);
    box-shadow      : unset;
}


/*--------------------------------------------------------------
# Formations
--------------------------------------------------------------*/
/*
** List 
*/
.formations .box-item {
    padding: 20px;
}

.formations .box-item .box-meta ul li {
    display        : flex;
    align-items    : flex-start;
    justify-content: flex-start;
    margin-bottom  : 15px;
}

.formations .box-item .box-meta ul li+li {
    padding-left: unset;
}

.formations .box-item .box-meta li i {
    color       : #777777;
    font-size   : 20px;
    margin-right: 5px;
    line-height : 20px;
}

/*
** Details 
*/
#formation .school-reviews.reviews .reviews-actions {
    justify-content: flex-end;
    margin-top     : 5px;
}

#formation .formation-data {
    width           : 35%;
    background-color: transparent;
}

/* #formation .formation-meta.single-meta {
    padding-bottom: 0;
} */

/*--------------------------------------------------------------
# Opportunities
--------------------------------------------------------------*/
/*
** Items 
*/
.opportunities .box {
    padding      : 30px;
    border-radius: 8px;
    transition   : 0.3s;
    box-shadow   : 0px 0px 5px rgb(0 0 0 / 25%);
    border       : 2px solid var(--dark-blue);
    background   : white;
}

.opportunities .box .box-image {
    max-height             : 440px;
    border-top-left-radius : 8px;
    border-top-right-radius: 8px;
    margin                 : -30px -30px 20px -30px;
    overflow               : hidden;
}

.opportunities .box .box-image img {
    transition: 0.5s;
    object-fit: cover;
}

.opportunities .box .box-title {
    font-size  : 24px;
    font-weight: bold;
    padding    : 0;
    margin     : 0 0 20px 0;
    transition : 0.3s;
    color      : var(--logo-dark-blue);
}

.opportunities .box .box-title a {
    color     : var(--logo-dark-blue);
    transition: 0.3s;
}

.opportunities .box .box-title a:hover {
    color: var(--secondary-blue);
}

.opportunities .box .box-badges {
    margin-bottom   : 20px;
    color           : #4084fd;
    background-color: transparent;
}

.opportunities .box .box-badges ul {
    display    : flex;
    flex-wrap  : wrap;
    list-style : none;
    align-items: center;
    padding    : 0;
    margin     : 0;
}

.opportunities .box .box-badges ul li+li {
    margin-left: 10px;
}

.opportunities .box .box-badges i {
    font-size   : 16px;
    margin-right: 8px;
    line-height : 0;
}

.opportunities .box .box-badges a {
    color      : #777777;
    font-size  : 14px;
    display    : inline-block;
    line-height: 1;
}

.opportunities .box .box-meta {
    margin-top      : 20px;
    margin-bottom   : 15px;
    background-color: transparent;
}

.opportunities .box .box-meta ul {
    display        : flex;
    flex-wrap      : wrap;
    list-style     : none;
    align-items    : center;
    justify-content: space-between;
    padding        : 0;
    margin         : 0;
}

.opportunities .box .box-meta ul li {
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.opportunities .box .box-meta ul li+li {
    padding-left: 15px;
}

.opportunities .box .box-meta i {
    color       : #4084fd;
    font-size   : 15px;
    margin-right: 5px;
    line-height : 0;
}

.opportunities .box .box-meta a {
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.opportunities .box .box-meta a,
.opportunities .box .box-meta span {
    display    : inline-block;
    color      : #777777;
    font-size  : 14px;
    line-height: 1;
}

.opportunities .box .box-content p {
    line-height: 24px;
}

.opportunities .box .box-content .read-more {
    -moz-text-align-last: right;
    text-align-last     : right;
}

.opportunities .box .box-content .read-more a {
    display      : inline-block;
    background   : var(--secondary-blue);
    color        : #fff;
    padding      : 6px 10px;
    transition   : 0.3s;
    font-size    : 14px;
    border-radius: 4px;
}

.opportunities .box .box-content .read-more a:hover {
    background: #5969f3;
}

.opportunities .box .box-content .official-link {
    -moz-text-align-last: left;
    text-align-last     : left;
}

.opportunities .box .box-content .official-link a {
    display      : inline-block;
    border       : 1px solid var(--secondary-blue);
    color        : var(--secondary-blue);
    padding      : 6px 10px;
    transition   : 0.3s;
    font-size    : 14px;
    border-radius: 4px;
}

.opportunities .box .box-content .official-link a:hover {
    border       : 1px solid var(--secondary-blue);
    color        : var(--secondary-blue);
    border-radius: 4px;
}

.opportunities .box .box-content h3 {
    font-size  : 22px;
    margin-top : 30px;
    font-weight: bold;
}

.opportunities .box .box-content blockquote {
    overflow        : hidden;
    background-color: #fafafa;
    padding         : 60px;
    position        : relative;
    text-align      : center;
    margin          : 20px 0;
}

.opportunities .box .box-content blockquote p {
    color        : #444444;
    line-height  : 1.6;
    margin-bottom: 0;
    font-style   : italic;
    font-weight  : 500;
    font-size    : 22px;
}

.opportunities .box .box-content blockquote::after {
    content         : '';
    position        : absolute;
    left            : 0;
    top             : 0;
    bottom          : 0;
    width           : 3px;
    background-color: var(--logo-dark-blue);
    margin-top      : 20px;
    margin-bottom   : 20px;
}

.opportunities .box .box-footer {
    padding-top: 10px;
    border-top : 1px solid #e6e6e6;
}

.opportunities .box .box-footer i {
    color  : #0d64fd;
    display: inline;
}

.opportunities .box .box-footer a {
    color     : #013289;
    transition: 0.3s;
}

.opportunities .box .box-footer a:hover {
    color: var(--secondary-blue);
}

.opportunities .box .box-footer .cats {
    list-style: none;
    display   : inline;
    padding   : 0 20px 0 0;
    font-size : 14px;
}

.opportunities .box .box-footer .cats li {
    display: inline-block;
}

.opportunities .box .box-footer .tags {
    list-style: none;
    display   : inline;
    padding   : 0;
    font-size : 14px;
}

.opportunities .box .box-footer .tags li {
    display: inline-block;
}

.opportunities .box .box-footer .tags li+li::before {
    padding-right: 6px;
    color        : #6c757d;
    content      : ",";
}

.opportunities .box .box-footer .share {
    font-size: 16px;
}

.opportunities .box .box-footer .share i {
    padding-left: 5px;
}

.opportunities .box:hover .box-title {
    color: var(--secondary-blue);
}

.opportunities .box:hover .box-image img {
    transform: rotate(6deg) scale(1.2);
}

/*
** Filters 
*/
.opportunities-filters .filters form button[type="submit"] {
    background: #0d6efd;
}

.opportunities-filters .filters form button[type="submit"]:hover {
    background: #0b5ed7;
}

.opportunities-filters .filters form button[type="button"] {
    border: 0.5px solid #0d6efd;
    color : #0d6efd;
}

.opportunities-filters .filters form button[type="button"]:hover {
    border: 1px solid #0b5ed7;
    color : #0b5ed7;
}


/*--------------------------------------------------------------
# Articles
--------------------------------------------------------------*/
.articles .slider-item {
    padding: 0;
}

.articles .post {
    transition     : 0.3s;
    min-height     : 350px;
    overflow       : hidden;
    border-radius  : 10px;
    position       : relative;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
}

.articles .post .post-background {
    width              : 100%;
    height             : 100%;
    overflow           : hidden;
    border-radius      : 10px;
    position           : absolute;
    background-position: center center;
    background-size    : cover;
    background-repeat  : no-repeat;
    transition         : 0.5s;
}

.articles .post .post-background::before {
    content      : '';
    position     : absolute;
    z-index      : 1;
    width        : 100%;
    height       : 100%;
    border-radius: 10px;
    bottom       : 0;
    left         : 0;
    overflow     : hidden;
    opacity      : 0.6;
    background   : #000000;
}

.articles .post .post-content {
    position        : relative;
    width           : 100%;
    height          : 100%;
    padding         : 30px 20px;
    z-index         : 2;
    background-color: transparent;
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
}

.articles .post .post-img {
    overflow: hidden;
    margin  : -30px -30px 15px -30px;
    position: relative;
}

.articles .post .post-img img {
    transition: 0.5s;
}

.articles .post .post-date {
    font-size    : 16px;
    font-weight  : 500;
    color        : whitesmoke;
    display      : block;
    margin-bottom: 10px;
}

.articles .post .post-title {
    font-size    : 20px;
    color        : whitesmoke;
    font-weight  : 700;
    margin-bottom: 20px;
    position     : relative;
    transition   : 0.3s;
}

.articles .post .readmore {
    display    : flex;
    align-items: center;
    font-weight: 600;
    line-height: 1;
    transition : 0.3s;
    color      : wheat;
}

.articles .post .readmore i {
    line-height: 0;
    margin-left: 4px;
    font-size  : 18px;
}

.articles .post:hover .post-title,
.articles .post:hover .readmore {
    color: var(--secondary-blue);
}

.articles .post:hover .post-img img,
.articles .post:hover .post-background {
    transform: rotate(6deg) scale(1.2);
}

/*
** Control Arrows 
*/
.articles-arrows {
    padding         : 0.5rem;
    margin          : 0 2rem;
    background-color: transparent;
    display         : flex;
    width           : 100%;
    align-items     : center;
    justify-content : flex-start;
}

.articles-arrows .control-arrow {
    z-index        : 1;
    width          : 35px;
    height         : 35px;
    color          : #212121;
    cursor         : pointer;
    background     : rgba(0, 0, 0, 0.10);
    font-size      : 1.2rem;
    border-radius  : 50px;
    user-select    : none;
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
    transition     : all 1s ease-in-out;
}

.articles-arrows .control-arrow:hover {
    color     : whitesmoke;
    background: rgba(0, 0, 0, 0.95);
}

.articles-arrows .control-arrow.arrow-next {
    margin-left: 2px;
}

.articles-arrows .control-arrow.arrow-prev {
    margin-right: 2px;
}

.articles-arrows .control-arrow.arrow-disabled {
    background    : rgba(0, 0, 0, 0.3);
    color         : rgba(0, 0, 0, 0.25);
    cursor        : not-allowed;
    pointer-events: none;
}


/*--------------------------------------------------------------
# Opportunity
--------------------------------------------------------------*/
.opportunity .box {
    padding         : 20px;
    box-shadow      : 0 4px 16px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius   : 5px;
}

/**
  * Opportunity Navigation 
  */
.previous-link,
.next-link {
    background-color: transparent;
}

.previous-link a,
.next-link a {
    color           : #c2c3cd;
    padding         : 20px 0;
    display         : flex;
    align-items     : center;
    justify-content : center;
    background-color: transparent;
}

.previous-link a:hover,
.next-link a:hover {
    color           : #74788d;
    background-color: rgba(116, 120, 141, 0.1);
    border-radius   : 4px;
}

.previous-link a i,
.next-link a i {
    font-size : 18px;
    transition: 0.5s;
}

.next-link a:hover i {
    transform: translateX(7px);
}

.previous-link a:hover i {
    transform: translateX(-7px);
}


/*--------------------------------------------------------------
# Countries Pages
--------------------------------------------------------------*/
/**
  * List 
  */
.countries .box {
    transition: 0.3s;
    position  : relative;
    overflow  : hidden;
    z-index   : 1;
    background: rgba(255, 255, 255, 0.60);
}

.countries .box::before {
    content   : "";
    background: rgba(255, 255, 255, 0.60);
    position  : absolute;
    left      : 30px;
    right     : 30px;
    top       : 30px;
    bottom    : 30px;
    transition: all ease-in-out 0.3s;
    z-index   : 2;
    opacity   : 0;
}

.countries .box img {
    transition: 1s;
}

.countries .box .box-data {
    opacity        : 1;
    position       : absolute;
    top            : 0;
    left           : 0;
    right          : 0;
    bottom         : 0;
    text-align     : center;
    z-index        : 3;
    transition     : all ease-in-out 0.3s;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
}

.countries .box .box-data::before {
    content        : "";
    opacity        : 1;
    position       : absolute;
    top            : 0;
    left           : 0;
    right          : 0;
    bottom         : 0;
    text-align     : center;
    z-index        : 3;
    transition     : all ease-in-out 0.3s;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    background     : rgba(0, 0, 0, 0.50);
}

.countries .box .box-data h4 {
    font-size  : 20px;
    color      : whitesmoke;
    font-weight: 700;
    z-index    : 4;
}

.countries .box .box-info {
    opacity        : 0;
    position       : absolute;
    top            : 0;
    left           : 0;
    right          : 0;
    bottom         : 0;
    text-align     : center;
    z-index        : 3;
    transition     : all ease-in-out 0.3s;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
}

.countries .box .box-info::before {
    display    : block;
    content    : "";
    width      : 48px;
    height     : 48px;
    position   : absolute;
    top        : 35px;
    left       : 35px;
    border-top : 3px solid rgba(1, 41, 112, 0.2);
    border-left: 3px solid rgba(1, 41, 112, 0.2);
    transition : all 0.5s ease 0s;
    z-index    : 9994;
}

.countries .box .box-info::after {
    display      : block;
    content      : "";
    width        : 48px;
    height       : 48px;
    position     : absolute;
    bottom       : 35px;
    right        : 35px;
    border-bottom: 3px solid rgba(1, 41, 112, 0.2);
    border-right : 3px solid rgba(1, 41, 112, 0.2);
    transition   : all 0.5s ease 0s;
    z-index      : 9994;
}

.countries .box .box-info h4 {
    font-size  : 20px;
    color      : var(--logo-dark-blue);
    font-weight: 700;
}

.countries .box .box-info p {
    color         : var(--logo-dark-blue);
    font-weight   : 600;
    font-size     : 14px;
    text-transform: uppercase;
    padding       : 0;
    margin        : 0;
}

.countries .box .box-links {
    text-align: center;
    z-index   : 4;
}

.countries .box .box-links a {
    color          : #fff;
    background     : var(--secondary-blue);
    margin         : 5px 2px;
    width          : 36px;
    height         : 36px;
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
    border-radius  : 50%;
    transition     : 0.3s;
}

.countries .box .box-links a i {
    font-size  : 20px;
    line-height: 0;
}

.countries .box .box-links a:hover {
    background: var(--dark-blue);
}

.countries .box:hover img {
    transform: scale(1.1);
}

.countries .box:hover::before {
    top    : 0;
    left   : 0;
    right  : 0;
    bottom : 0;
    opacity: 1;
}

.countries .box:hover .box-data {
    opacity: 0;
}

.countries .box:hover .box-data::before {
    opacity: 0;
}

.countries .box:hover .box-info {
    opacity: 1;
}

.countries .box:hover .box-info::before {
    top : 15px;
    left: 15px;
}

.countries .box:hover .box-info::after {
    bottom: 15px;
    right : 15px;
}


/**
  * Country Banner
  */
.country-banner,
.school-banner {
    height    : var(--banner-height);
    background: transparent;
}

.country-banner .leading,
.school-banner .leading {
    height          : var(--banner-height);
    background-color: transparent;
    bottom          : unset;
    top             : unset;
}


/**
  * Country Tabs 
  */
.country-tabs .card {
    height       : 100%;
    min-height   : 400px;
    border-radius: .50rem;
}

.country-tabs .card-title {
    font-weight  : 500;
    font-family  : 'Montserrat', sans-serif;
    margin-bottom: 0;
}

.country-tabs .card-body {
    color: #212121;
}

.country-tabs .card-text {
    font-family: 'Montserrat', sans-serif;
    color      : #212121;
}

.country-tabs .nav .nav-item {
    flex         : 1;
    margin-bottom: 8px;
    margin-right : 5px;
}

.country-tabs .nav .nav-item:last-child {
    margin-right: 0;
}

.country-tabs .nav .nav-item .nav-link {
    display        : flex;
    flex-direction : column;
    align-items    : stretch;
    justify-content: center;
    padding        : 24px 16px;
    text-align     : center;
    height         : 100%;
    background     : rgba(85, 110, 230, 0.05);
    color          : #495057;
    font-weight    : 500;
    font-family    : 'Montserrat', sans-serif;
}

.country-tabs .nav .nav-item .nav-link.active {
    color           : #fff;
    background-color: #556ee6;
}

.country-tabs .nav .nav-item .nav-link.active:hover {
    color           : #fff;
    background-color: #556ee6;
}

.country-tabs .nav .nav-item .nav-link:hover {
    color: #556ee6;
}

.country-tabs .nav .nav-item .nav-link .nav-icon {
    font-size: 24px;
}

/**
  * Country Sliders 
  */
.country-sliders .box,
.domain-sliders .box {
    height          : 250px;
    display         : flex;
    padding         : 2px;
    border-radius   : 2px;
    background-color: transparent;
}

.country-sliders .box img,
.domain-sliders .box img {
    height    : 100%;
    width     : 100%;
    object-fit: cover;
}


/**
  * Country Square
  */
#countries .square {
    margin : 0;
    padding: 15px;
}

#countries .square .square-tags,
#countries .square .square-item {
    margin-bottom: 0;
}

#countries .square .square-tags ul {
    margin: 0;
}

#countries .square .square-tags ul a {
    margin-bottom: 0;
}


/**
* Custom tostify colors
**/
.Toastify__toast {
    font-family: 'Montserrat', sans-serif !important;
}

.Toastify__toast-theme--dark,
.Toastify__toast-theme--light {
    color: white !important;
}

.Toastify__toast-theme--colored.Toastify__toast--default {
    background: #74788d !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    background: #34c38f !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
    background: #50a5f1 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
    background: #f1b44c !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
    background: #f46a6a !important;
}


/**
* avatars
**/
.avatar-xs {
    height: 2rem;
    width : 2rem;
}

.avatar-sm {
    height: 3rem;
    width : 3rem;
}

.avatar-md {
    height: 4.5rem;
    width : 4.5rem;
}

.avatar-lg {
    height: 6rem;
    width : 6rem;
}

.avatar-xl {
    height: 7.5rem;
    width : 7.5rem;
}

.avatar-title {
    align-items     : center;
    background-color: var(--logo-dark-blue);
    color           : white;
    display         : flex;
    font-weight     : "bold";
    height          : 100%;
    justify-content : center;
    width           : 100%;
}


/**
* Login, Register
**/
.auth-form {
    padding         : 2rem;
    background-color: white;
    border-radius   : 5px;
    margin          : 3rem 0.5rem;
}


/**
* Truncate text
**/
.ellipsis-text {
    width           : 100%;
    overflow        : hidden;
    white-space     : nowrap;
    text-overflow   : ellipsis;
    background-color: transparent;
}


/**
* Wrapped text
**/
.wrapped-text {
    width           : 100%;
    white-space     : normal;
    background-color: transparent;
    display         : inline-block;
}


/**
* React tel input
**/
.react-tel-input .form-control {
    width: inherit;
}



/**
* card radio
**/
.card-radio {
    background-color: #ffffff;
    border          : 2px solid #ced4da;
    border-radius   : .25rem;
    padding         : 1rem;
    overflow        : hidden;
    text-overflow   : ellipsis;
    white-space     : nowrap;
}

.card-radio:hover {
    cursor: pointer;
}

.card-radio-label {
    display: block;
}

.card-radio-input {
    display: none;
}

.card-radio-input:checked+.card-radio {
    border-color: var(--secondary-blue) !important;
}



/**
* Font sizes
**/
.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-26 {
    font-size: 26px !important;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-30 {
    font-size: 30px !important;
}


/**
* Blocker Styles
**/
.leave-links {
    padding: 7px 5px;
}

.leave-links div {
    cursor: pointer;
}

.leave-links a,
.leave-links div {
    font-size: 12px;
    padding  : 0 5px;
    color    : var(--main-grey);
}

.leave-links a:hover,
.leave-links div:hover {
    color: gray;
}
.offer-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-blue);
    border-color: var(--secondary-blue);
    color: whitesmoke;
    padding: 6px 10px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 4px;
}
.offer-btn:focus,
.offer-btn:active,
.offer-btn:focus-visible,
.offer-btn:hover {
    background-color: var(--main-blue)!important;
    border-color: var(--main-blue)!important;
    color: white!important;
}