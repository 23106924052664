/*
*******************************************
@File: Filters Styles
*******************************************
*/
.filters {
    width           : 100%;
    padding         : 2px;
    border-radius   : 4px;
}

.filters.filters-white {
    background-color: #fff;
}

.filters.filters-beige {
    background-color: gainsboro;
}

.filters.filters-shadow {
    box-shadow: 0 .45rem .25rem rgba(18, 38, 63, .03);
}

.filters.filters-border {
    border: 1px solid #e1ecff;
}

.filters form {
    position  : relative;
    background: transparent;
    padding   : 6px;
}

.filters form button {
    display        : flex;
    align-items    : center;
    justify-content: center;
    height         : 100%;
    min-height     : 100%;
    width          : 100%;
    text-align     : center;
    font-size      : 15px;
    transition     : 0.3s;
    border-radius  : 4px;
}

.filters form button i {
    font-size: 1.3rem;
}

.filters form button[type="submit"] {
    border    : 0;
    background: #25ca6f;
    color     : #fff;
}

.filters form button[type="submit"]:hover {
    background: #13a456;
}

.filters form button[type="submit"] span {
    margin-left: 3px;
}

.filters form button[type="button"] {
    border    : 0.5px solid #25ca6f;
    background: transparent;
    color     : #25ca6f;
}

.filters form button[type="button"]:hover {
    border    : 1px solid #13a456;
    background: transparent;
    color     : #13a456;
}

.filters .filters-selects-prefix__control {
    height    : 100% !important;
    min-height: 100% !important;
    border    : none !important;
    color     : #212121 !important;
    padding   : 0.2rem 0 !important;
}

.filters .filters-selects-prefix__control:hover {
    cursor: pointer !important;
}

.filters .form-control {
    height    : 100% !important;
    min-height: 100% !important;
    padding   : 0.5rem .75rem !important;
    border    : none !important;
    color     : #212121 !important;
    background-color: transparent;
    font-size: 14px !important;
}

.filters .filters-vertical .filters-selects-prefix__control,
.filters .filters-vertical .form-control {
    background-color: #f7f7f7;
}

.filters .filters-horizontal .filters-selects-prefix__control,
.filters .filters-vertical .form-control {
    background-color: transparent;
}

@media (max-width: 815px) {
    .filters .form-control,
    .filters .filters-horizontal .filters-selects-prefix__control {
        background-color: #f7f7f7;
    }
}