/*
*******************************************
@File: Leading Styles
*******************************************
*/
.leading {
    position        : absolute;
    bottom          : 20%;
    top             : 20%;
    right           : 15%;
    left            : 15%;
    z-index         : 10;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    background-color: transparent;
    padding         : 0.5rem 1rem;
}

.leading h1 {
    font-size       : clamp(0.5rem, 8vw, 1.5rem);
    font-weight     : 500;
    text-transform  : uppercase;
    text-shadow     : 0px 0px 20px rgba(0, 0, 0, 0.4);
    text-align      : center;
    margin-bottom   : 0.7rem;
    color           : white;
    background-color: transparent;
    white-space     : normal;
}

.leading p {
    text-shadow     : 0px 0px 20px rgba(0, 0, 0, 0.4);
    font-size       : 16px;
    text-align      : center;
    background-color: transparent;
    margin-bottom   : 1.5rem;
    color           : white;
    white-space     : normal;
}

@media (min-width: 992px) {
    .leading p {
        width: 50%;
    }
}

@media (max-width: 900px) {
    .leading {
        right           : 5%;
        left            : 5%;
    }
}