/*
*******************************************
@File: Sliders Styles
*******************************************
*/

.sliders {
    background-color: transparent;
    padding         : 0.5rem;
}

.slider-class {
    background-color: transparent;
    padding         : 0.5rem;
}

.slider-item {
    background-color: transparent;
    padding         : 0.5rem;
}

/*
** Control Arrows 
*/
.custom-arrows {
    padding         : 0.5rem;
    margin          : 0 2rem;
    background-color: transparent;
    display         : flex;
    width           : 100%;
    align-items     : center;
    justify-content : flex-start;
}

.control-arrow {
    z-index        : 1;
    width          : 35px;
    height         : 35px;
    color          : #212121;
    cursor         : pointer;
    background     : rgba(0, 0, 0, 0.10);
    font-size      : 1.2rem;
    border-radius  : 50px;
    user-select    : none;
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
    transition     : all 1s ease-in-out;
}

.control-arrow:hover {
    color     : whitesmoke;
    background: rgba(0, 0, 0, 0.95);
}

.control-arrow.arrow-next {
    margin-left: 2px;
}

.control-arrow.arrow-prev {
    margin-right: 2px;
}

.control-arrow.arrow-disabled {
    background    : rgba(0, 0, 0, 0.3);
    color         : rgba(0, 0, 0, 0.25);
    cursor        : not-allowed;
    pointer-events: none;
}